import React from "react";
import "./TodayScheduleComponent.scss";

const TodayScheduleComponent = ({ allAlarmToArray }) => {
  const tabSchedule = allAlarmToArray.map((alarms) => {
    if (alarms !== "") {
      let alarmWork = window.localStorage.getItem(alarms).split("º")[1];
      let alarmTime = window.localStorage.getItem(alarms).split("º")[2];
      return (
        <tr key={alarms}>
          <td>{alarmTime}</td>
          <td>{alarmWork}</td>
        </tr>
      );
    }
  });
  return (
    <div className="todaysTodo">
      <table className="tabSchedule">
        <tbody>
          <tr>
            <th>Time</th>
            <th>Today's to-do</th>
          </tr>
          {tabSchedule}
        </tbody>
      </table>
    </div>
  );
};

export default TodayScheduleComponent;
