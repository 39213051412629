import React from "react";
import styledCom, { keyframes } from "styled-components";
import hand_min from "../../../img/Clock_Hand_Min.svg";
// 분 ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const handMinAni = ({ TimeMinDeg, TimeMinDeg360turn }) => keyframes`
        0% {
        transform:rotate(${TimeMinDeg}deg);
        }
        100% {
        transform:rotate(${TimeMinDeg360turn}deg);
        }`;

const Min = styledCom.img`
    position: absolute;
    width: 45%;
    z-index:9998;
    height: 70%;
    animation:${handMinAni} 3600s forwards linear infinite;
    `;
Min.defaultProps = {
  src: hand_min,
  alt: "아날로그 시계",
};

const MinComponent = ({ TimeDeg, TimeDeg360turn }) => {
  return (
    <Min
      TimeMinDeg={TimeDeg.nowMindeg}
      TimeMinDeg360turn={TimeDeg360turn.Min}
    ></Min>
  );
};

export default MinComponent;
