import React from "react";
import styledCom from "styled-components";
import handAlarm from "../../../img/handAlarm.svg";
// src={handAlarm} alt='alarm' style='transform:rotate("+{alarmDeg}deg); position:absolute; width:50% ; height:50%;'></img>
const Alarm = styledCom.img`
transform:rotate(${(props) => props.alarmDeg}deg);
position:absolute;
width:50%;
height:50%;


`;

Alarm.defaultProps = {
  src: handAlarm,
  alt: "alarm",
};

const AlarmComponent = ({ allAlarmToArray, hideAlarm }) => {
  const myAlarms = allAlarmToArray.map((alarms) => {
    if (alarms !== "" && hideAlarm !== true) {
      let alarmDeg = window.localStorage.getItem(alarms).split("º")[0];
      return <Alarm key={alarms} alarmDeg={alarmDeg} />;
    }
  });

  return <>{myAlarms}</>;
};

export default React.memo(AlarmComponent);
