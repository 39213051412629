import { React } from "react";
import "./Header.css";
import wakeabird from "../../../img/wakeAbird.svg";
import { Link } from "react-router-dom";

function Header() {
  // 링크 태그 사용하면 페이지를 새로 불러오지않고 상태 유지해서
  // 시계의 시 분 초가 처음상태로 돌아감
  // 그래서 a태그사용
  return (
    <div className="header">
      <div style={{ marginLeft: "3vw", marginRight: "3vw" }}>
        <a href="/">- English</a>
        <a href="/kr">- 한국어</a>
      </div>
      <div>
        <a href="/ch">- 中文</a>
        <a href="/jp">- 日本語</a>
      </div>
      <img
        src={wakeabird}
        alt="wakeAbird"
        style={{ height: "100%", flex: "4 1 0" }}
      ></img>
      <div style={{ flex: "1 1 0" }}></div>
    </div>
  );
}
export default Header;
