import React from "react";
import styledCom, { keyframes } from "styled-components";
import hand_hour from "../../../img/Clock_Hand_Hour.svg";
// 시 ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const handHourAni = ({ TimeHourDeg, TimeHourDeg360turn }) => keyframes`
    0% {
    transform:rotate(${TimeHourDeg}deg);
    }
    100% {
    transform:rotate(${TimeHourDeg360turn}deg);
    }`;

const Hour = styledCom.img`
    position: absolute;
    
    width: 40%;
    height: 53%;
    animation:${handHourAni} 43200s forwards linear infinite;
    `;

Hour.defaultProps = {
  src: hand_hour,
  alt: "online clock",
};

const HourComponent = ({ TimeDeg360turn }) => {
  return (
    <Hour
      TimeHourDeg={TimeDeg360turn.nowHourdeg}
      TimeHourDeg360turn={TimeDeg360turn.Hour}
    ></Hour>
  );
};

export default HourComponent;
