import { React, useEffect, useState, useRef, useCallback } from "react";
import "./Contents.scss";
import soundNote from "../../../img/soundNote.png";
import Moment from "react-moment";
import alarmSoundSrc from "../../../sound/birdSound.mp3";
import SecComponent from "../../views/Contents/SecComponent";
import MinComponent from "../../views/Contents/MinComponent";
import HourComponent from "../../views/Contents/HourComponent";
import AlarmComponent from "../../views/Contents/AlarmComponent";
import TodayScheduleComponent from "../../views/Contents/TodayScheduleComponent";

function Contents({ Time, TimeDeg, TimeDeg360turn }) {
  // 알람 설정ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
  const [Form, setForm] = useState({
    InputHours: 12,
    InputMinutes: 0,
    InputSeconds: 0,
  });
  const [InputWork, setInputWork] = useState("");
  let [InputSound, setInputSound] = useState(undefined);
  const [AlarmWork, setAlarmWork] = useState(false);
  const alarmTimer = useRef(null);
  const audioSource = useRef(null);
  const soundName = useRef(null);
  const audioP = useRef(null);
  const [hideAlarm, setHideAlarm] = useState(false);
  const singbirdAni = useRef(null);

  function formHandler(e) {
    const alarmForm = {
      ...Form,
      [e.target.name]: parseInt(e.target.value),
    };

    setForm(alarmForm);
  }

  // 알람음 설정
  if (InputSound !== undefined) {
    // 파일 선택 누르고 선택하지않고 취소누른경우 기본 알람 설정되도록
    if (InputSound[0] === undefined) {
      audioSource.current.setAttribute("src", alarmSoundSrc);
    }
    InputSound[0] !== undefined
      ? (soundName.current.innerHTML = InputSound[0].name)
      : (soundName.current.innerHTML = "bird.MP3");

    let binaryData = [];
    binaryData.push(InputSound[0]);
    InputSound = window.URL.createObjectURL(new Blob(binaryData));
  }

  const allAlarm = window.localStorage.getItem("allAlarm");
  let allAlarmToArray = [];

  if (allAlarm != null) {
    allAlarmToArray = allAlarm.split("º");
  }

  const onAlarmSet = useCallback(
    (event) => {
      event.preventDefault();
      if (
        Form.InputHours >= 24 ||
        Form.InputMinutes >= 60 ||
        Form.InputSeconds >= 60
      ) {
        return false;
      }

      const alarmToSeconds = {
        Hour: Form.InputHours * 3600,
        Min: Form.InputMinutes * 60,
        // 13시 14시... 각도 초기화 (43200 == 12시간 == 한바퀴) ex))18시 == 540' 을 6시 == 180'로 변경
        func: function () {
          if (Form.InputHours >= 12) {
            alarmToSeconds.Hour -= 43200;
          }
        },
      };
      alarmToSeconds.func();

      const alarmTimeToSeconds =
        Form.InputHours * 3600 + Form.InputMinutes * 60 + Form.InputSeconds;
      const alarmTimeTodeg =
        (Form.InputSeconds + alarmToSeconds.Min + alarmToSeconds.Hour) / 120;

      const InputToString = {
        // 시간 표기 ex)) 01:03:05
        Hour: Form.InputHours.toString(),
        Min: Form.InputMinutes.toString(),
        Sec: Form.InputSeconds.toString(),

        func: function () {
          if (Form.InputHours < 10) {
            InputToString.Hour = "0" + InputToString.Hour;
          }
          if (Form.InputMinutes < 10) {
            InputToString.Min = "0" + InputToString.Min;
          }
          if (Form.InputSeconds < 10) {
            InputToString.Sec = "0" + InputToString.Sec;
          }
        },
      };

      InputToString.func();

      let alreadyExistsAlarm = allAlarmToArray.includes(
        "Alarm" + alarmTimeToSeconds
      );

      // 이미 존재하는 알람이면 시간중복되서 추가안되도록
      if (!alreadyExistsAlarm) {
        allAlarm != null
          ? window.localStorage.setItem(
              "allAlarm",
              allAlarm + "Alarm" + alarmTimeToSeconds + "º"
            )
          : window.localStorage.setItem(
              "allAlarm",
              "Alarm" + alarmTimeToSeconds + "º"
            );
      }

      window.localStorage.setItem(
        "Alarm" + alarmTimeToSeconds,
        alarmTimeTodeg +
          "º" +
          InputWork +
          "º" +
          InputToString.Hour +
          ":" +
          InputToString.Min +
          ":" +
          InputToString.Sec +
          "º" +
          alarmTimeToSeconds
      );

      window.location.replace("/");
    },
    [Form, InputWork]
  );

  const alarmSoundEnded = useCallback((event) => {
    // createObjectURL 해준거 지워줌 메모리 누수 방지
    window.URL.revokeObjectURL(audioSource.current.getAttribute("src"));
  }, []);

  const alarmSoundCheck = useCallback((event) => {
    event.target.checked
      ? (audioP.current.volume = 0)
      : (audioP.current.volume = 1);
  }, []);

  const checkHideAlarm = useCallback((event) => {
    event.target.checked ? setHideAlarm(true) : setHideAlarm(false);
  }, []);

  // 어두운모드
  // const modeToggle = (event) => {

  //     console.log(event.target.checked);

  // }

  const deleteConfirm = useCallback((event) => {
    if (window.confirm("Are you sure you want to delete all?") === true) {
      window.localStorage.clear();
      window.location.replace("/");
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    let currentTimeToSec = Time.Seconds + Time.Minutes * 60 + Time.Hours * 3600;
    // localStorage 영역에서 알람 가져오기
    // length-1 << allAlarm.split("º")하면 로직상 맨뒤에 빈 배열이 남게됌 해당 빈 배열 없애주는 작업
    for (let i = 0; i < allAlarmToArray.length - 1; i++) {
      let alarmTimeToSec = window.localStorage
        .getItem(allAlarmToArray[i])
        .split("º")[3];

      // // 1)) 앞으로 다가올 시간에 대한 알람(알람맞춘 시간-현재시간) ex))18:00에 18:30 알람. 2)) 다음날 알람[(오늘 하루 남은시간)+(알람맞춘 시간)] ex)) 18:00에 다음날 17:30 알람.
      currentTimeToSec < alarmTimeToSec
        ? (alarmTimer.current = setTimeout(() => {
            setAlarmWork(true);
          }, 1000 * (alarmTimeToSec - currentTimeToSec)))
        : (alarmTimer.current = setTimeout(() => {
            setAlarmWork(true);
          }, 1000 * (alarmTimeToSec - currentTimeToSec + 86400)));
    }

    return () => {
      clearTimeout(alarmTimer.current);
    };
  }, []);

  // 알람이 울릴때 실행
  useEffect(() => {
    if (AlarmWork) {
      //setInterval 이용해 5초간 알람 애니메이션 구현한 후 setAlarmWork는 다시 false로

      // 설정해둔 알람음이 있다면
      if (InputSound !== undefined) {
        audioSource.current.setAttribute("src", InputSound);
      }

      audioP.current.load();
      audioP.current.play();

      // 알람 울리면 나타나는 새 애니메이션 관리
      singbirdAni.current.classList.add("dsp");

      singbirdAni.current.addEventListener("animationend", function (e) {
        singbirdAni.current.classList.remove("dsp");
        singbirdAni.current.classList.add("dsp2");
      });
      console.log(
        new Date().getHours() +
          ":" +
          new Date().getMinutes() +
          ":" +
          new Date().getSeconds()
      );
      setAlarmWork(false);
    }

    return () => {};
  }, [AlarmWork]);

  return (
    <div className="contents">
      <div className="clockSetWrap">
        <div className="singbirdAni" ref={singbirdAni}></div>
        <audio className="audioP" ref={audioP} onEnded={alarmSoundEnded}>
          <source
            className="audioSource"
            src={alarmSoundSrc}
            type="audio/MP3"
            ref={audioSource}
          ></source>
        </audio>
        <div className="clock">
          <SecComponent TimeDeg={TimeDeg} TimeDeg360turn={TimeDeg360turn} />
          <MinComponent TimeDeg={TimeDeg} TimeDeg360turn={TimeDeg360turn} />
          <HourComponent TimeDeg360turn={TimeDeg360turn} />
          <AlarmComponent
            allAlarmToArray={allAlarmToArray}
            hideAlarm={hideAlarm}
          />
        </div>
        <div className="settings">
          <div
            style={{
              width: "100%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <fieldset>
              <legend>Alarm</legend>
              <form>
                <div>
                  <input
                    type="number"
                    min="0"
                    max="23"
                    placeholder="12"
                    name="InputHours"
                    onChange={formHandler}
                  ></input>
                  <label>hr</label>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    placeholder="00"
                    name="InputMinutes"
                    onChange={formHandler}
                  ></input>
                  <label>min</label>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    placeholder="00"
                    name="InputSeconds"
                    onChange={formHandler}
                  ></input>
                  <label>seconds</label>
                </div>
                <div style={{ paddingTop: "1vw", paddingBottom: "1vw" }}>
                  <input
                    type="text"
                    placeholder="Today's to-do"
                    value={InputWork}
                    onChange={(e) => setInputWork(e.currentTarget.value)}
                  ></input>
                </div>
                <button
                  className="submitBtn"
                  type="submit"
                  onClick={onAlarmSet}
                >
                  Set
                </button>
              </form>
            </fieldset>

            <div className="ADstyle">
              <label style={{ cursor: "pointer" }}>
                Sound
                <span className="soundName" ref={soundName}>
                  bird.MP3
                </span>
                <img src={soundNote} alt="alarm sound" />
                <input
                  type="file"
                  className="upload"
                  accept="audio/*"
                  onChange={(e) => setInputSound(e.currentTarget.files)}
                  style={{ display: "none" }}
                />
              </label>
            </div>

            <div className="ADstyle">
              <label>
                Sound off
                <input type="checkbox" onClick={alarmSoundCheck} />
              </label>
            </div>
            <div className="ADstyle">
              <label>
                Alarm hide
                <input type="checkbox" onClick={checkHideAlarm} />
              </label>
            </div>

            {/* <div className="ADstyle">
                            <span style={{ fontSize: "1.5vw" }}>어두운 모드 </span>
                            <label className="switch">

                                <input type="checkbox" className="toggle" onClick={modeToggle} />
                                <span className="slider round"></span>
                            </label>
                        </div> */}

            <div className="ADstyle">
              <button onClick={deleteConfirm}>Delete All Alarms</button>
            </div>
          </div>
        </div>
      </div>
      <div className="timeLine">
        <div>
          <Moment local interval={1} />
        </div>
      </div>

      <TodayScheduleComponent allAlarmToArray={allAlarmToArray} />
    </div>
  );
}
export default Contents;
