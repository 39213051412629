import { React, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./components/views/LandingPage/LandingPage";
import LandingPageKr from "./components/views/LandingPage/LandingPageKr";
import LandingPageCh from "./components/views/LandingPage/LandingPageCh";
import LandingPageJp from "./components/views/LandingPage/LandingPageJp";

function App() {
  // 구글 애드센스
  // useEffect(() => {
  //   let script = document.createElement('script');
  //   script.setAttribute('data-ad-client','ca-pub-7640508129890443' );
  //   script.setAttribute('async','');
  //   script.setAttribute('src','https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
  //   document.getElementsByTagName('head')[0].appendChild(script);

  //   return () => {

  //   }
  // }, [])

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/kr" component={LandingPageKr} />
        <Route path="/ch" component={LandingPageCh} />
        <Route path="/jp" component={LandingPageJp} />
        <Route
          render={({ location }) => (
            <div>Not Found {location.pathname} path</div>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
