import React from "react";
import styledCom, { keyframes } from "styled-components";
import hand_sec from "../../../img/Clock_Hand_Sec.svg";
// 초 ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const handSecAni = ({ TimeSecDeg, TimeSecDeg360turn }) => keyframes`
    0% {
    transform:rotate(${TimeSecDeg}deg);
    
    }
    100% {
    transform:rotate(${TimeSecDeg360turn}deg);
    }`;

const Sec = styledCom.img`
        position: absolute;
        width: 70%;
        z-index:9999;
        height: 70%;
        animation:${handSecAni} 60s forwards linear infinite;
        
        `;
Sec.defaultProps = {
  src: hand_sec,
  alt: "온라인 시계",
};

const SecComponent = ({ TimeDeg, TimeDeg360turn }) => {
  return (
    <Sec
      TimeSecDeg={TimeDeg.nowSecdeg}
      TimeSecDeg360turn={TimeDeg360turn.Sec}
    ></Sec>
  );
};

export default SecComponent;
