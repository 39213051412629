import React from "react";
import "./LandingPage.css";
import Header from "../Header/Header.js";
import Contents from "../Contents/Contents.js";
import Footer from "../Footer/Footer.js";

// 시계ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ

const date = new Date();

const nowTime = {
  Seconds: date.getSeconds() + date.getMilliseconds() / 3000,
  Minutes: date.getMinutes(),
  Hours: date.getHours(),
};

const nowTimeDeg = {
  nowSecdeg: nowTime.Seconds * 6,
  // 1초당 0.1deg씩 분침에 줘야 보다 정확히 분침이 가리킨다 ex)) 10분 30초일때 분침은 60deg 가 아닌 30초를 더한 63deg다
  nowMindeg: nowTime.Minutes * 6 + nowTime.Seconds / 10,
  HoursToSeconds: nowTime.Hours * 3600,

  // 13시 14시 ... 각도 초기화 (43200 == 12시간  == 한바퀴)
  func: function () {
    if (nowTime.Hours >= 12) {
      nowTimeDeg.HoursToSeconds -= 43200;
    }
  },
};
nowTimeDeg.func();
// 현재 시간 초로나타냄 ()
const nowTimeToSeconds =
  nowTime.Seconds + nowTime.Minutes * 60 + nowTimeDeg.HoursToSeconds;
// 120초 == 1deg // 현재시간(초)/120 == 시침 각도
const nowHourdeg = nowTimeToSeconds / 120;

// 현재 각도를 중심으로 360'
const now360turn = {
  Sec: nowTimeDeg.nowSecdeg + 360,
  Min: nowTimeDeg.nowMindeg + 360,
  Hour: nowHourdeg + 360,
  nowHourdeg: nowHourdeg,
};

//ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ

function LandingPage(props) {
  return (
    <div className="LandingPage">
      <Header />
      <hr />
      <Contents
        Time={nowTime}
        TimeDeg={nowTimeDeg}
        TimeDeg360turn={now360turn}
      />

      <Footer />
    </div>
  );
}
export default LandingPage;
